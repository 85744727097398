import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";

import AppBar from "@Components/appBar/appBar";
import Footer from "@Components/common/footer";
import Hero from "@Components/index/hero";
import firebase from "@Config/firebaseInit";
import SEO from "@Components/common/seo";

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: "popup",
  // signInSuccessUrl: "/account",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"])),
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: "flex",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundBlendMode: "multiply",
      height: "100vh",
    },
    videoContainer: {
      position: "relative",
    },
    video: {
      right: 0,
      bottom: 0,
      width: "100%",
      overflowX: "hidden",
      minHeight: "100vh",
      objectFit: "cover",
    },
    overlay: {
      display: "flex",
      position: "absolute",
      backgroundColor: "rgba(0,0,0,0.1)",
      backgroundBlendMode: "multiply",
      top: 0,
      height: "100%",
      width: "100%",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 600,
      },
    },
    sectionTitle: {
      padding: "60px 0",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        padding: 60,
      },
    },
    sectionDesc: {
      [theme.breakpoints.up("sm")]: {
        height: 200,
      },
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      textAlign: "justify",
    },
    button: {
      width: 200,
      // display: "block",
      margin: "60px auto",
    },
  })
);

export default function Home() {
  const { t: t_common } = useTranslation("common");
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const [user, loading, error] = useAuthState(firebase.auth());

  return (
    <React.Fragment>
      <SEO pageTitle="アカウント" />

      <AppBar noMargin={true}>
        <Hero
          title="ログイン（準備中）"
          desc={
            user
              ? "ログイン成功！"
              : "[準備中] ログインすることで、記事や宿を保存できます。"
          }
          button={user ? "ブックマーク一覧" : undefined}
          link="/account/bookmarks"
          icon={<BookmarkBorderOutlinedIcon />}
        >
          {/*<StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />*/}
        </Hero>
        <Footer />
      </AppBar>
    </React.Fragment>
  );
}
